import image1 from '../images/catalog/1.png';
import image2 from '../images/catalog/2.png';
import image3 from '../images/catalog/3.png';
import image4 from '../images/catalog/4.png';
import image5 from '../images/catalog/5.png';
import image6 from '../images/catalog/6.png';
import image7 from '../images/catalog/7.png';
import image8 from '../images/catalog/8.png';
import image9 from '../images/catalog/9.png';
import image10 from '../images/catalog/10.png';

import { ProductList } from "./types";

export const products: ProductList = [
  {
    id: '1',
    title: 'Звездное небо в банке',
    price: 1500,
    discount: 0.2,
    imageUrl: image1,
  },
  {
    id: '2',
    title: 'Умный цветок',
    price: 2500,
    discount: 0.1,
    imageUrl: image2,
  },
  {
    id: '3',
    title: 'Магическая свеча',
    price: 800,
    imageUrl: image3,
  },
  {
    id: '4',
    title: 'Винтажная фотокамера "Путешествие в прошлое"',
    price: 3500,
    discount: 0.3,
    imageUrl: image4,
  },
  {
    id: '5',
    title: 'Подводный дрон "Морская тайна"',
    price: 6000,
    imageUrl: image5,
  },
  {
    id: '6',
    title: 'Космический коврик для йоги',
    price: 2000,
    discount: 0.1,
    imageUrl: image6,
  },
  {
    id: '7',
    title: 'Цифровой арт-планшет',
    price: 4500,
    discount: 0.2,
    imageUrl: image7,
  },
  {
    id: '8',
    title: 'Волшебный аудиофлакон',
    price: 1200,
    imageUrl: image8,
  },
  {
    id: '9',
    title: 'Робот-пылесос со встроенным аромадиффузором',
    price: 5500,
    discount: 0.1,
    imageUrl: image9,
  },
  {
    id: '10',
    title: 'Художественная коробка сюрпризов',
    price: 3000,
    discount: 0.1,
    imageUrl: image10,
  },
];
