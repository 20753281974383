import { FC } from "react";

import { ProductList } from "../../components/ProductList/ProductList";

import { products } from "../../data/products";
import { Heading } from "@chakra-ui/react";

import "./Catalog.css";
import ParseXml from "../../components/ParseXml/ParseXml";


const Catalog: FC = () => {

  return (
    <div className="container catalog">
      <Heading as='h1'  color='var(--red)' fontSize='18px' lineHeight='21px'>
        КАТАЛОГ СУВЕНИРНОЙ ПРОДУКЦИИ
      </Heading>
      <div className='data'>
        <ParseXml />
        <ProductList products={ products } />
      </div>

    </div>
  );
};

export default Catalog;
