import React, { useEffect, useState } from 'react';

import type { MenuProps } from 'antd';
import { Menu, ConfigProvider } from 'antd';

import { PageType, PageList } from "../../data/types";
import PagesTree from "./PagesTree";

import xml_data from "./tree";

import "./ParseXml.css";

type MenuItem = Required<MenuProps>['items'][number];

interface LevelKeysProps {
  key?: string;
  children?: LevelKeysProps[];
}

const getLevelKeys = (items1: LevelKeysProps[]) => {
  const key: Record<string, number> = {};
  const func = (items2: LevelKeysProps[], level = 1) => {
    items2.forEach((item) => {
      if (item.key) {
        key[item.key] = level;
      }
      if (item.children) {
        func(item.children, level + 1);
      }
    });
  };
  func(items1);
  return key;
};

const ParseXml = () => {

  const [data, setData] = useState<PageList>([]);

  const levelKeys = getLevelKeys(data as LevelKeysProps[]);

  const [stateOpenKeys, setStateOpenKeys] = useState(['2', '23']);

  const onOpenChange: MenuProps['onOpenChange'] = (openKeys) => {
    const currentOpenKey = openKeys.find((key) => stateOpenKeys.indexOf(key) === -1);
    // open
    if (currentOpenKey !== undefined) {
      const repeatIndex = openKeys
        .filter((key) => key !== currentOpenKey)
        .findIndex((key) => levelKeys[key] === levelKeys[currentOpenKey]);

      setStateOpenKeys(
        openKeys
          // remove repeat key
          .filter((_, index) => index !== repeatIndex)
          // remove current level all child
          .filter((key) => levelKeys[key] <= levelKeys[currentOpenKey]),
      );
    } else {
      // close
      setStateOpenKeys(openKeys);
    }
  };

  const [current, setCurrent] = useState('mail');

  const onClick: MenuProps['onClick'] = (e) => {
    setCurrent(e.key);
  };

  useEffect(() => {

    const parser = new DOMParser();
    const xmlDoc = parser.parseFromString(xml_data, "text/xml");
    const catalog: PageList = [];

    const mainPage = xmlDoc.querySelector('page');

    const parsePage = (page: Element, page_id: string) : PageType => {

      const child_elements = page.querySelectorAll(`page[parent_page_id=\"${page_id}\"]`);

      const child_pages: PageList = [];

      child_elements.forEach((child_element) => {
        const child_id = child_element.querySelector("page_id")!.textContent || '';
        // console.log(`Ищем ${child_id} в ${page_id}`);
        child_pages.push(parsePage(child_element, child_id));
      });

      // console.log(child_pages);

      const thisPage:PageType = {
        page_id: page.querySelector("page_id")!.textContent || '',
        parent_page_id: null,
        label: page.querySelector("name")!.textContent || '',
        key: page.querySelector("page_id")!.textContent || '',
          // child_pages.length > 0 ?
          // // page.querySelector("parent_page_id")!.textContent === '1' ?
          // //   'SubMenu' :
          // //   'sub2'
          // 'sub2'
          // :
          // 'alipay',
        // class: 'ant-menu-padding',
        uri: page.querySelector("uri")!.textContent || '',//
        children: child_pages.length > 0 ? child_pages : undefined
      };

      return thisPage;

    }

    if (mainPage) {
      const catalog_elements = mainPage.querySelectorAll(`page[parent_page_id='1']`);
      catalog_elements.forEach((el) => {
        if (el.querySelector("page_id")) {
          const el_id = el.querySelector("page_id")!.textContent || '';
          catalog.push(parsePage(el, el_id));
        }
      })
      setData(catalog);
    }

  }, []);

  if (!data) {
    return <div>Loading...</div>;
  }

  return (
    <ConfigProvider
      theme={{
        components: {
          Menu: {
            horizontalItemHoverColor: '#c82020',
            horizontalItemSelectedColor: '#c82020',
            itemSelectedColor: '#c82020'
          },
        },
      }}
    >
      <Menu
        onClick={onClick}
        selectedKeys={[current]}
        mode="horizontal"
        inlineCollapsed={false}
        // inlineIndent={100}
        items={data}
      />
    </ConfigProvider>
  )
  {
    data.map((item) => (
      <PagesTree data={item} key={item.page_id}/>
    ))
  }

  // <Menu
  //   mode="inline"
  //   defaultSelectedKeys={['231']}
  //   openKeys={stateOpenKeys}
  //   onOpenChange={onOpenChange}
  //   style={{ width: 250 }}
  //   items={data}
  // />

  // <div className='container'>
  //
  //   <Menu onClick={onClick} selectedKeys={[current]} mode="horizontal" items={data} />
  //   {
  //     data.map((item) => (
  //       <PagesTree data={item} key={item.page_id}/>
  //     ))
  //   }
  // </div>;
};

export default ParseXml;
