import { FC } from 'react';

import { Product } from "../../data/types";

import './ProductCard.css';

export const ProductCard: FC<Product> = ({ title, price, discount, imageUrl }) => {
  return (
    <li className = 'product-card'>
      <img src={ imageUrl } alt={ title } className='product-card__image'></img>
      <div className='product-card__price-container'>
        { discount ? <p className='product-card__price price-discounted '>{ price - price * discount }</p> : <p className='product-card__price'>{ price }</p>}
        { discount && (<p className='product-card__discount'>{ price } Р</p>)}
      </div>
      <p className = 'product-card__title'>{ title }</p>
    </li>
  );
}
